<template>
  <category-list :type="type" :name="name" :max="max"></category-list>
</template>
<script>
import CategoryList from "@/components/CategoryList";

export default {
  components: {
    CategoryList,
  },
  data() {
    return {
      type: 211,
      name: "common.category",
      max: 10,
    };
  },
};
</script>